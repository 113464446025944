import * as React from 'react';
import { Component } from 'react';
import ArticleLayout from "../../articles/article-layout";
import danielle_img_1 from '../../../images/DanielleCampbell.jpg';
import danielle_img_1_mobile from '../../../images/DanielleCampbell_mobile.jpg';

const fullName = "Danielle Campbell";

const copyList = [

    {
        type: "image",
        content: {
            src: danielle_img_1,
            mobileSrc: danielle_img_1_mobile,
            alt: "Tony sitting down and looking into the camera",
            caption: "Danielle Campbell"
        }
    },
    {
        type: "text",
        content: "<h3 class=\"title\">A conversation with Danielle Campbell, MPH, a researcher within the AIDS Clinical Trials Group (ACTG) Network and community volunteer"
    },
    // {
    //     type: "subheading2",
    //     content: "<p class=\"sub-title2\">The Advocate"
    // },

    {
        type: 'text',
        content: "There’s a fire in the belly of researcher and activist Danielle Campbell that was ignited back in grade school. She remembers taking the bus from her Watts neighborhood to a very different community so that she could receive a better education. “I understood early on that I went to school where no one looked like me. Where the homes looked different, the families, stores, and neighborhood looked different,” she says of her first recognition of educational and socioeconomic disparities. It fueled her early desire to become a lawyer. “I’ve always known that fighting systems of power that create systemic oppression was something I wanted to do.”"
    },
    {
        type: "blockquote",
        content: "“I’ve always known that fighting systems of power that create systemic oppression was something I wanted to do.”",
        author: fullName
    },
    {
        type: 'text',
        content: `Her true mission came into focus when she witnessed disparities in
        healthcare. “I was 12 when I learned about death, as a result of the lack of a
        cancer screening,” she says of losing her
        grandfather. “My perspective shifted significantly. I thought,
        if I could occupy space, it would be a space where I could provide treatment
        to marginalized communities to help them stay well.” Campbell further refined
        that mission while attending UC Irvine, where she recalls a lecture on the
        HIV crisis in the Black community that took hold of her. “The professor starts talking about how HIV disproportionately 
        impacts women of African descent, and I thought to myself, ‘Why?’ I started googling. In 2018, Black
        women made up less than 15% of the female population in the US, but we accounted for nearly 60% of new HIV infections in women.”`
    },
    {
        type: "text",
        content: "Since deciding to pursue public health, there’s been no stopping Campbell. From serving 11 years as a volunteer counselor for a mobile testing program to occupying multiple leadership roles within the American Public Health Association (APHA) she’s stayed true to her mission: “Protect people like me who don’t have anything, who came from nothing.” And she’s learned that to better protect Black women from HIV, they must be included in the research. As she explains, “What people have access to as far as prevention, treatments, or a future cure all starts with research.” That’s why she’s worked tirelessly to help bridge that gap, which seems at times to be a thankless job. But, she says, “sometimes, just sometimes, we win.” One of those times was in late 2022, when after a year of painstakingly hard work proposing a new policy statement supporting women’s inclusion in HIV-related research to the APHA, the policy statement was adopted."
    },
    {
        type: "text",
        content: `Now, she’s focused on securing her next win, a doctorate from UC San Diego. And with many broadcasting the need to end the HIV epidemic, Campbell knows leveling up as she simultaneously works to level
        the playing field for people of color, particularly Black women, in the new HIV landscape is her best
        next step. “It’s a social disease. So, to help end it, you must address the social and structural factors that create systemic oppression that
        shows up in the bodies of people who are oppressed,” she says. Therein lies the fire for
        equality and liberation that she’s kept burning since childhood. “Coming from
        where I’m from, social justice is a part of my DNA.”`
    },
    // {
    //     type: "signatureName",
    //     content: "<h2 class=\"signatureName\">DANIELLE CAMPBELL"
    // },
    // {
    //     type: "signaturedesc",
    //     content: "<h2 class=\"signaturedesc\">AIDS Clinical Trials Group (ACTG) Network"
    // },
    {
        type: "championsofchangelandinglink",
          content: "<a href=\"/championsofchange\">Learn more about all our Champions of Change honorees "

    },

]
class Content extends Component {
  render() {
    return (
      <div className={`championsofchangearticle ${this.props.show ? 'd-block' : 'd-none'}`}>

      <ArticleLayout
        page={this.props.page}

        copyList={copyList}
        subheading="Champions of Change: A Celebration of Black Women Changemakers in HIV"
        heading="Danielle Campbell, The Advocate"
      />

        {/*<Container fluid className={`${this.props.page} hero`} />
        <Container className={`${this.props.page}`}>
          <Row>
            <Col xs={9} lg={10} className='m-auto px-0'>
              <div className='hero-callout muted-yellow-dark'>
                <h1 className='sub-title '>Dafina</h1>
                <h1 className='title '>Dafina Ward - Champion of Change</h1>
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur
                sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </p>
            </Col>
          </Row>
    </Container>*/}



      </div>
    );
  }
}

export default Content;
